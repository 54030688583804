import React, { useState } from "react";

const AppContext = React.createContext({
	isLoggedIn: false,
	role: "",
	year: {},
	login: (role) => {},
	logout: () => {},
	setYear: (year) => {},
});

export const AppContextProvider = (props) => {
	const initialUser = localStorage.getItem("logged_in");
	const initialRole = localStorage.getItem("role");
	const initialYear = localStorage.getItem("year");
	const [isLoggedIn, setLoggedIn] = useState(initialUser);
	const [role, setRole] = useState(initialRole);
	const [year, setYear] = useState(JSON.parse(initialYear));

	const loginHandler = (role) => {
		setLoggedIn(true);
		setRole(role);
		localStorage.setItem("logged_in", true);
		localStorage.setItem("role", role);
	};

	const logoutHandler = () => {
		setLoggedIn(false);
		localStorage.removeItem("logged_in");
		localStorage.removeItem("role");
		localStorage.removeItem("year");
	};

	const setYearHandler = (year) => {
		setYear(year);
		localStorage.setItem("year", JSON.stringify(year));
	};

	const contextValue = {
		isLoggedIn: isLoggedIn,
		role: role,
		year: year,
		login: loginHandler,
		logout: logoutHandler,
		setYear: setYearHandler,
	};

	return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export default AppContext;
