import React from "react";
import {
	Button,
	Card,
	Col,
	Descriptions,
	Modal,
	Row,
	Space,
	Steps,
	Table,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";
import { format } from "../../../../utils/Formatter";
import dayjs from "dayjs";
import localeId from "dayjs/locale/id";
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import { HiOutlineClipboard } from "react-icons/hi2";

const itemColumns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		width: 200,
	},
	{
		title: "Tipe",
		dataIndex: "type",
		key: "type",
		width: 100,
		render: (type) => {
			return type === "ONCE" ? (
				<Tag color="red">Sekali</Tag>
			) : type === "MONTHLY" ? (
				<Tag color="green">Bulanan</Tag>
			) : type === "SEMESTRIAL" ? (
				<Tag color="blue">Persemester</Tag>
			) : type === "PERIODICAL" ? (
				<Tag color="yellow">Periode</Tag>
			) : (
				<Tag>Lainnya</Tag>
			);
		},
	},
	{
		title: "Nominal",
		dataIndex: "nominal",
		key: "nominal",
		render: (text) => format(text),
	},
];

const InvoiceDetailModal = ({ invoice, isVisible, onClose, onPay }) => {
	const [isLoading, setLoading] = React.useState(false);
	const [mappedItems, setMappedItems] = React.useState([]);

	React.useEffect(() => {
		if (invoice) {
			const mapped = invoice.items.map((item) => ({
				key: item._id,
				name: item.fee.name,
				type: item.fee.type,
				nominal: item.nominal,
			}));
			setMappedItems(mapped);
		} else {
			setMappedItems([]);
		}
	}, [invoice]);

	const handleClose = () => {
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={1024}
				title={"Detail Tagihan"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				{invoice && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col span={24}>
							<Space direction="vertical">
								<Typography.Title level={3} style={{ margin: 0 }}>
									#{invoice.number}
								</Typography.Title>
							</Space>
						</Col>
						<Col lg={16} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail Siswa" size="small">
									{invoice.student && (
										<Descriptions layout="vertical" column={2}>
											<Descriptions.Item label="Nama">{invoice.student.name}</Descriptions.Item>
											<Descriptions.Item label="No. Induk">
												{invoice.student.number}
											</Descriptions.Item>
											<Descriptions.Item label="Kelas">
												{invoice.student.classroom.name}
											</Descriptions.Item>
											<Descriptions.Item label="Tahun Akademik">
												{invoice.year.name}
											</Descriptions.Item>
										</Descriptions>
									)}
								</Card>
								<Card title="Item Tagihan" size="small">
									<Table
										size="small"
										columns={itemColumns}
										dataSource={mappedItems}
										loading={isLoading}
										bordered
										pagination={false}
										className="ant-border-space"
									/>
								</Card>

								<Card title="Ringkasan Tagihan" size="small">
									<Descriptions layout="horizontal" column={1} bordered size="small">
										<Descriptions.Item label="Biaya Total">
											{format(invoice.items.map((i) => i.nominal).reduce((a, b) => a + b, 0))}
										</Descriptions.Item>
										<Descriptions.Item label="Biaya Lain">
											{format(invoice.fees.map((f) => f.nominal).reduce((a, b) => a + b, 0))}
										</Descriptions.Item>
										<Descriptions.Item label="Total">{format(invoice.total)}</Descriptions.Item>
									</Descriptions>
								</Card>

								{/* <Card
									title="Histori Transaksi"
									size="small"
									extra={
										(orderDetail.status === "PLACED" || orderDetail.status === "CONFIRMED") &&
										(orderDetail.invoice.status === "UNPAID" ||
											orderDetail.invoice.status === "PARTLY_PAID") && (
											<Button onClick={() => handleAddTransaction(true)} type="primary">
												Tambah
											</Button>
										)
									}>
									<AddTransactionModal
										isVisible={isAddOrderTransactionModalVisible}
										isLoading={isLoading}
										onCancel={() => handleAddTransaction(false)}
										initialValue={orderDetail.invoice.remainingTotal}
										referenceId={orderDetail.invoice._id}
										onLoading={(v) => onLoading(v)}
										onSuccess={handleOnAddedTransaction}
									/>
									<Table
										size="small"
										columns={transactionColumns}
										dataSource={mappedTransactions}
										loading={isLoading}
										bordered
										pagination={{
											position: "bottomRight",
											pageSizeOptions: ["2", "5", "10"],
											showSizeChanger: true,
											locale: { items_per_page: "" },
										}}
										className="ant-border-space"
									/>
								</Card> */}
							</Space>
						</Col>
						<Col lg={8} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail Tagihan" size="small">
									<Descriptions layout="vertical" column={1}>
										<Descriptions.Item label="Tanggal Penagihan">
											{dayjs(invoice.issuedAt).locale("id-ID").format("dddd, D MMMM YYYY")}
										</Descriptions.Item>
										<Descriptions.Item label="Tanggal Pembuatan">
											{dayjs(invoice.createdAt)
												.locale("id-ID")
												.format(`dddd, D MMMM YYYY ${localeId.formats.LT}`)}
										</Descriptions.Item>
										<Descriptions.Item label="Status Tagihan">
											{invoice.status === "DRAFT" && (
												<Tag
													style={{ marginRight: 0 }}
													icon={<ExclamationCircleOutlined />}
													color="error">
													DRAF
												</Tag>
											)}
											{invoice.status === "SENT" && (
												<Tag
													style={{ marginRight: 0 }}
													icon={<SyncOutlined spin />}
													color="processing">
													DIKIRIM
												</Tag>
											)}
											{invoice.status === "PARTIAL" && (
												<Tag
													style={{ marginRight: 0 }}
													icon={<CheckCircleOutlined />}
													color="warning">
													SEBAGIAN
												</Tag>
											)}
											{invoice.status === "PAID" && (
												<Tag
													style={{ marginRight: 0 }}
													icon={<CheckCircleOutlined />}
													color="success">
													TERBAYAR
												</Tag>
											)}
											{invoice.status === "VOID" && (
												<Tag
													style={{ marginRight: 0 }}
													icon={<CloseCircleOutlined />}
													color="default">
													TIDAK SAH
												</Tag>
											)}
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card title="Histori Tagihan" size="small">
									<Space
										style={{
											width: "100%",
										}}
										direction="vertical"
										size="middle">
										<Steps
											size="small"
											style={{ width: "100%" }}
											progressDot
											direction="vertical"
											current={invoice.events.length}
											type="default"
											items={invoice.events.map((event) => {
												return {
													title: (
														<span>
															{event.status}
															{event.reference && (
																<span
																	style={{
																		color: (event.reference.nominal ?? 0) > 0 ? "green" : "red",
																	}}>
																	{` + ${
																		event.reference ? format(event.reference.nominal ?? 0) : null
																	}`}
																</span>
															)}
															{event.reference && event.reference._id && (
																<Typography.Text
																	style={{
																		display: "flex",
																		alignItems: "center",
																		color: "gray",
																	}}
																	copyable={{
																		text: event.reference?._id,
																		tooltips: ["Salin nomor ID", "Berhasil disalin"],
																	}}>
																	{event.reference?._id}
																</Typography.Text>
															)}
														</span>
													),
													description: dayjs(event.date)
														.locale("id-ID")
														.format("dddd, D MMMM YYYY HH:mm"),
												};
											})}
										/>
										{(invoice.status === "SENT" || invoice.status === "PARTIAL") && (
											<Button
												style={{
													width: "100%",
												}}
												type="primary"
												onClick={() => {
													onPay(invoice);
												}}>
												Tambah Pembayaran
											</Button>
										)}
									</Space>
								</Card>
							</Space>
						</Col>
					</Row>
				)}
			</Modal>
		</>
	);
};

export default InvoiceDetailModal;
