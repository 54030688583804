import {
	Button,
	Card,
	Col,
	Form,
	Input,
	notification,
	Row,
	Space,
	Spin,
	Switch,
	Table,
} from "antd";
import React, { useEffect } from "react";
import SearchInput from "../../components/SearchInput";
import SchoolForm from "./components/SchoolForm";
import {
	requestGetModuleList,
	requestShowSchool,
	requestUpdateSchool,
} from "../../services/admin.service";
import SchoolQuotaProgressBar from "./components/SchoolQuotaProgressBar";
import { format } from "../../utils/Formatter";
import { DeleteFilled, PlusCircleFilled, SaveFilled } from "@ant-design/icons";

const SettingPage = () => {
	const [bankAccountForm] = Form.useForm();
	const [isLoading, setLoading] = React.useState(false);
	const [modules, setModules] = React.useState([]);
	const [school, setSchool] = React.useState({});
	const [isAddBankAccount, setIsAddBankAccount] = React.useState(false);

	useEffect(() => {
		fetchModuleList();
		fetchSchool();
	}, []);

	const fetchSchool = () => {
		setLoading(true);
		requestShowSchool({
			join: "students",
		})
			.then((response) => {
				setSchool(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchModuleList = () => {
		requestGetModuleList({})
			.then((response) => {
				setModules(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSearch = () => {};

	const handleSubmitBankAccount = () => {
		bankAccountForm
			.validateFields()
			.then((values) => {
				setLoading(true);
				requestUpdateSchool({
					bankAccounts: [...school.bankAccounts, values],
				})
					.then((response) => {
						setSchool(response.data.data);
						setIsAddBankAccount(false);
						bankAccountForm.resetFields();
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleDeleteBankAccount = (bankAccount) => {
		setLoading(true);
		requestUpdateSchool({
			bankAccounts: school.bankAccounts.filter((item) => item._id !== bankAccount._id),
		})
			.then((response) => {
				setSchool(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Spin
			style={{
				width: "100%",
				height: "100%",
			}}
			spinning={isLoading}>
			{school && school.quotas && (
				<div className="tabled">
					<Row gutter={16}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Space
								direction="vertical"
								size="middle"
								style={{
									width: "100%",
								}}>
								<Card
									bordered={false}
									className="circlebox tablespace mb-24"
									title="Umum"
									extra={
										<Space>
											<SearchInput onSearch={handleSearch} />
										</Space>
									}>
									<div
										style={{
											padding: 24,
											width: "100%",
										}}>
										<SchoolForm modules={modules} school={school} />
									</div>
								</Card>
							</Space>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Space
								direction="vertical"
								size="middle"
								style={{
									width: "100%",
								}}>
								<Card
									bordered={false}
									className="circlebox tablespace"
									title="Rekening Bank"
									extra={
										<Space>
											<SearchInput onSearch={handleSearch} />
											<Button type="primary" onClick={() => setIsAddBankAccount(!isAddBankAccount)}>
												Tambah
											</Button>
										</Space>
									}>
									<div
										style={{
											paddingTop: 16,
											paddingRight: 16,
											paddingLeft: 16,
											paddingBottom: 24,
											width: "100%",
										}}>
										<p
											style={{
												marginLeft: 8,
											}}>
											<strong>Rekening Bank</strong> dapat digunakan untuk transaksi pembayaran
											transfer manual.
										</p>
										<Table
											style={{
												width: "100%",
											}}
											bordered
											dataSource={school.bankAccounts.map((bankAccount) => ({
												...bankAccount,
												key: bankAccount._id,
											}))}
											columns={[
												{
													title: "Bank",
													dataIndex: "bankName",
													key: "bankName",
												},
												{
													title: "Nomor Rekening",
													dataIndex: "accountNumber",
													key: "accountNumber",
												},
												{
													title: "Atas Nama",
													dataIndex: "accountName",
													key: "accountName",
												},
												{
													title: "Aksi",
													key: "action",
													render: (text, record) => (
														<Space size="middle">
															<Button
																type="dashed"
																danger
																size="small"
																onClick={() => handleDeleteBankAccount(record)}>
																<DeleteFilled />
															</Button>
														</Space>
													),
												},
											]}
											pagination={false}
											size="small"
										/>
										{isAddBankAccount && (
											<div
												style={{
													marginTop: 16,
													marginLeft: 8,
													marginRight: 8,
												}}>
												<Form
													form={bankAccountForm}
													style={{
														width: "100%",
													}}>
													<Row gutter={16}>
														<Col span={6}>
															<Form.Item
																style={{
																	marginBottom: 0,
																}}
																name="bankName"
																rules={[
																	{
																		required: true,
																		message: "Nama Bank harus diisi!",
																	},
																]}>
																<Input placeholder="Nama Bank" />
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																style={{
																	marginBottom: 0,
																}}
																name="accountNumber"
																rules={[
																	{
																		required: true,
																		message: "Nomor Rekening harus diisi!",
																	},
																]}>
																<Input placeholder="Nomor Rekening" />
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																style={{
																	marginBottom: 0,
																}}
																name="accountName"
																rules={[
																	{
																		required: true,
																		message: "Atas Nama harus diisi!",
																	},
																]}>
																<Input placeholder="Atas Nama" />
															</Form.Item>
														</Col>
														<Col
															span={2}
															style={{
																textAlign: "right",
															}}>
															<Form.Item
																style={{
																	marginBottom: 0,
																}}>
																<Button
																	loading={isLoading}
																	style={{
																		padding: 0,
																		width: "100%",
																	}}
																	onClick={handleSubmitBankAccount}
																	type="primary">
																	<SaveFilled />
																</Button>
															</Form.Item>
														</Col>
													</Row>
												</Form>
											</div>
										)}
									</div>
								</Card>
								<Card
									bordered={false}
									className="circlebox tablespace"
									title="Modul"
									extra={
										<Space>
											<SearchInput onSearch={handleSearch} />
										</Space>
									}>
									<div
										style={{
											paddingTop: 16,
											paddingRight: 16,
											paddingLeft: 16,
											paddingBottom: 24,
											width: "100%",
										}}>
										<Table
											style={{
												width: "100%",
											}}
											bordered
											dataSource={modules.map((module) => ({
												...module,
												key: module._id,
												price: module.price,
												status: school.modules.includes(module.code) ? true : false,
											}))}
											columns={[
												{
													title: "Modul",
													dataIndex: "name",
													key: "name",
												},
												{
													title: "Harga",
													dataIndex: "price",
													key: "price",
													render: (price) => (
														<span>
															{format(price.actual)}
															{/* <span
																style={{
																	fontSize: 10,
																	color: "#999",
																}}>
																{price.type === "flat" ? "" : "/siswa/thn"}
															</span> */}
														</span>
													),
												},
												{
													title: "Status",
													dataIndex: "status",
													key: "status",
													render: (status, record) => (
														<Switch
															checkedChildren="ON"
															unCheckedChildren="OFF"
															checked={status}
															disabled
														/>
													),
												},
											]}
											pagination={false}
											size="small"
											summary={(pageData) => {
												let total = 0;
												pageData.forEach(({ price, status }) => {
													if (status) {
														total += price.actual;
													}
												});
												return (
													<>
														<Table.Summary.Row>
															<Table.Summary.Cell colSpan={1}>
																<strong>Total</strong>
															</Table.Summary.Cell>
															<Table.Summary.Cell colSpan={2}>
																<strong>{format(total)}</strong>
															</Table.Summary.Cell>
														</Table.Summary.Row>
													</>
												);
											}}
										/>
									</div>
								</Card>
								<Card
									bordered={false}
									className="circlebox tablespace"
									title="Quota"
									extra={
										<Space>
											<SearchInput onSearch={handleSearch} />
										</Space>
									}>
									<Space
										style={{
											padding: 24,
										}}>
										<SchoolQuotaProgressBar school={school} />
									</Space>
								</Card>
							</Space>
						</Col>
					</Row>
				</div>
			)}
		</Spin>
	);
};

export default SettingPage;
