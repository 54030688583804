import React, { useState } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Progress, Space, Typography } from "antd";
import { useEffect } from "react";

const SchoolQuotaProgressBar = ({ school }) => {
	return (
		<>
			<div
				style={{
					marginBottom: 10,
				}}>
				{school.quotas.map((quota) => {
					return (
						<Space key={quota.key} style={{ width: "100%" }} direction="vertical" align="center">
							<Progress
								type="circle"
								percent={(quota.used / quota.value) * 100 ?? 0}
								format={(percent) =>
									`${((percent / 100) * quota.value ?? 100).toFixed(0)} / ${quota?.value ?? 100}`
								}
							/>
							<Typography.Text strong>{quota.name}</Typography.Text>
						</Space>
					);
				})}
			</div>
		</>
	);
};
export default SchoolQuotaProgressBar;
