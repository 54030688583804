/* eslint-disable react-hooks/exhaustive-deps */
import {
	App,
	Button,
	Card,
	Col,
	Descriptions,
	Modal,
	Popconfirm,
	Row,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect } from "react";
import { requestShowClassroom, unenrollStudents } from "../../../../services/teacher.service";
import { YearFilter } from "../../../../components/YearFilter";
import { HiClipboardDocumentCheck, HiClipboardDocumentList } from "react-icons/hi2";
import ClassroomDailyReportFormModal from "./ClassroomDailyReportFormModal";
import StudentEnrollFormModal from "../../../../components/StudentEnrollFormModal";

const studentColumns = [
	{
		title: "No",
		dataIndex: "order",
		key: "order",
	},
	{
		title: "No. Induk",
		dataIndex: "number",
		key: "number",
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const lessonColumns = [
	{
		title: "Matpel",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Pengajar",
		dataIndex: "teachers",
		key: "teachers",
	},
];

export const ClassroomDetailModal = ({
	activeYear,
	id,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();
	const [classroom, setClassroom] = React.useState(null);
	const [isTeacherAssignVisible, setTeacherAssignVisible] = React.useState(false);
	const [isStudentEnrollVisible, setStudentEnrollVisible] = React.useState(false);
	const [isDailyReportFormVisible, setDailyReportFormVisible] = React.useState(false);

	const [years, setYears] = React.useState([]);

	const [students, setStudents] = React.useState([]);
	const [lessons, setLessons] = React.useState([]);
	const [mappedLessons, setMappedLessons] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [selectedYear, setSelectedYear] = React.useState(activeYear);
	const [selectedYearId, setSelectedYearId] = React.useState(activeYear._id);

	const [lesson, setLesson] = React.useState(null);
	const [assignType, setAssignType] = React.useState("");
	const [guidanceTeacher, setGuidanceTeacher] = React.useState(null);

	useEffect(() => {
		if (id) {
			fetchClassroom();
		} else {
			setClassroom(null);
			setAssignType("");
			setGuidanceTeacher(null);
		}
	}, [id]);

	useEffect(() => {
		if (classroom) {
			updateMappedItems();
		}
	}, [selectedYearId]);

	useEffect(() => {
		if (classroom) {
			updateMappedItems();
		}
	}, [classroom]);

	const updateMappedItems = () => {
		const year = classroom.years.find((year) => year._id === selectedYearId);

		if (!year) {
			return;
		}

		const lessons = year.lessons;
		const students = year.students;

		setLessons(lessons);
		setStudents(students);

		// search guidance teacher
		let isGuidanceTeacherFound = false;
		if (lessons.length > 0) {
			lessons[0].teachers.forEach((teacher) => {
				if (teacher.type === "GUIDANCE") {
					isGuidanceTeacherFound = true;
					setGuidanceTeacher(teacher);
				}
			});
		}
		if (!isGuidanceTeacherFound) {
			setGuidanceTeacher(null);
		}

		const mappedLessons = lessons.map((lesson) => {
			const teachers = lesson.teachers;

			return {
				key: lesson._id,
				name: lesson.subject.name,
				teachers: (
					<>
						<Space direction="horizontal">
							{teachers
								.filter((t) => t.type !== "GUIDANCE")
								.map((teacher, index) => {
									return (
										<span key={index}>
											<Typography.Text>
												{teacher.name}
												<Tag
													color="blue"
													style={{
														marginLeft: 4,
														marginRight: 0,
													}}>
													{teacher.type === "MAIN"
														? "Utama"
														: teacher.type === "ASSISTANT"
														? "Pendamping"
														: "Pengganti"}
												</Tag>
											</Typography.Text>
										</span>
									);
								})}
						</Space>
					</>
				),
			};
		});

		setMappedLessons(mappedLessons);

		const mappedStudents = students.map((student, index) => {
			return {
				key: student._id,
				order: index + 1,
				number: student.number,
				name: student.name,
				actions: (
					<Space>
						<Popconfirm
							placement="topRight"
							title={`Anda yakin akan mengeluarkan ${student.name}?`}
							onConfirm={(e) => handleUnenrollStudent(student._id)}
							okText="Ya"
							cancelText="Tdk">
							<Button type="dashed" size="small" shape="default" danger>
								Keluarkan
							</Button>
						</Popconfirm>
					</Space>
				),
			};
		});

		setMappedStudents(mappedStudents);
	};

	const fetchClassroom = () => {
		onLoading(true);
		requestShowClassroom(id)
			.then((response) => {
				const classroom = response.data.data;

				setClassroom(classroom);
				setYears(classroom.years);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnenrollStudent = (studentId) => {
		onLoading(true);
		unenrollStudents({
			ids: [studentId],
			classroomId: classroom._id,
			yearId: selectedYearId,
		})
			.then((response) => {
				fetchClassroom();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		setClassroom(null);
		setYears([]);
		setMappedLessons([]);
		setMappedStudents([]);
		setSelectedYear(activeYear);
		setSelectedYearId(activeYear._id);
		setLesson(null);
		onClose();
	};

	const handleEnrollStudentButton = () => {
		setStudentEnrollVisible(true);
	};

	const handleEnrollStudentClose = () => {
		setStudentEnrollVisible(false);
	};

	const handleEnrollStudentSuccess = () => {
		setStudentEnrollVisible(false);
		fetchClassroom();
	};

	const handleDailyReportClose = () => {
		setDailyReportFormVisible(false);
	};

	const handleDailyReportSuccess = () => {
		setDailyReportFormVisible(false);
		fetchClassroom();
	};

	const handleAssignTeacherButton = (lesson, type) => {
		if (!lesson) {
			notification["error"]({
				message: "Kesalahan!",
				description: "Minimal ada 1 pelajaran untuk kelas ini.",
			});
			return;
		}
		setLesson(lesson);
		setAssignType(type);
		setTeacherAssignVisible(true);
	};

	const handleAssignTeacherClose = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
	};

	const handleAssignTeacherSuccess = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
		fetchClassroom();
	};

	return (
		<>
			<StudentEnrollFormModal
				isVisible={isStudentEnrollVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleEnrollStudentClose}
				onSuccess={handleEnrollStudentSuccess}
				classroom={classroom}
				year={selectedYear}
			/>
			<ClassroomDailyReportFormModal
				isVisible={isDailyReportFormVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleDailyReportClose}
				onSuccess={handleDailyReportSuccess}
				classroom={classroom}
				year={selectedYear}
				students={students}
			/>
			<Modal
				maskClosable={false}
				width={1024}
				title={"Detail Kelas"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				{classroom && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col span={24}>
							<Space direction="horizontal">
								<Typography.Title level={3} style={{ margin: 0 }}>
									{classroom.name}
								</Typography.Title>
								<YearFilter
									activeYear={activeYear}
									years={years}
									resettable={false}
									filteredBy={{
										key: selectedYearId,
										value: selectedYear !== null ? selectedYear.name : "",
									}}
									filterBy={(key) => {
										setSelectedYearId(key);
										if (key !== "") {
											const year = years.find((year) => year._id === key);
											setSelectedYear(year);
										} else {
											setSelectedYear(null);
										}
									}}
								/>
							</Space>
						</Col>
						<Col lg={14} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail Kelas" size="small">
									<Descriptions layout="vertical" column={2}>
										<Descriptions.Item label="Nama">{classroom.name}</Descriptions.Item>
										<Descriptions.Item label="Tingkat">{classroom.grade}</Descriptions.Item>
										<Descriptions.Item label="Wali Kelas">
											{guidanceTeacher && (
												<span>
													{lessons.length > 0 && guidanceTeacher.name}
													<Tag
														color="blue"
														style={{ marginLeft: 4, marginRight: 0, fontWeight: "normal" }}>
														Wali Kelas
													</Tag>
												</span>
											)}
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card title={"Aksi"} size="small">
									<Row gutter={[8, 8]}>
										<Col span={12}>
											<Button
												type="dashed"
												style={{ width: "100%" }}
												onClick={() => setDailyReportFormVisible(true)}>
												<HiClipboardDocumentCheck
													style={{
														marginRight: 4,
													}}
												/>{" "}
												Buat Laporan Harian
											</Button>
										</Col>
										<Col span={12}>
											<Button type="dashed" style={{ width: "100%" }} disabled>
												<HiClipboardDocumentList
													style={{
														marginRight: 4,
													}}
												/>{" "}
												Isi Nilai Raport
											</Button>
										</Col>
									</Row>
								</Card>
								<Card title="Pelajaran" size="small">
									<Table
										size="small"
										columns={lessonColumns}
										dataSource={mappedLessons}
										loading={isLoading}
										bordered
										pagination={{
											position: "bottomRight",
											pageSizeOptions: ["10", "20", "50", "100"],
											showSizeChanger: true,
											locale: { items_per_page: "" },
										}}
										className="ant-border-space"
									/>
									<Typography.Text
										type="secondary"
										style={{
											display: "block",
										}}>
										Untuk dapat menambah atau menghapus pelajaran, silahkan ke menu
										<strong> pelajaran </strong>.
									</Typography.Text>
								</Card>
							</Space>
						</Col>
						<Col lg={10} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card
									title={
										<>
											<span>Siswa</span>
											<Button
												type="default"
												size="small"
												onClick={handleEnrollStudentButton}
												style={{ float: "right" }}>
												<PlusCircleFilled /> Tambahkan Siswa
											</Button>
										</>
									}
									size="small">
									<Table
										size="small"
										columns={studentColumns}
										dataSource={mappedStudents}
										loading={isLoading}
										bordered
										pagination={{
											position: "bottomRight",
											pageSizeOptions: ["10", "20", "50", "100"],
											showSizeChanger: true,
											locale: { items_per_page: "" },
										}}
										className="ant-border-space"
									/>
								</Card>
							</Space>
						</Col>
					</Row>
				)}
			</Modal>
		</>
	);
};

export default ClassroomDetailModal;
