import React, { useContext, useEffect } from "react";
import {
	App,
	Button,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Row,
	Select,
	Spin,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import dayjs from "dayjs";
import AppContext from "../../../../store/app.context";
import {
	requestGenerateInvoices,
	requestGetFeeList,
	requestGetYearList,
} from "../../../../services/admin.service";
import InvoiceStudentInput from "./InvoiceStudentInput";
import InvoiceItemInput from "./InvoiceItemInput";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const InvoiceGenerateFormModal = ({ isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const context = useContext(AppContext);
	const { message, notification, modal } = App.useApp();
	const [years, setYears] = React.useState([]);
	const [fees, setFees] = React.useState([]);
	const [year, setYear] = React.useState(null);
	const [semester, setSemester] = React.useState(null);
	const [feeRows, setFeeRows] = React.useState([]);
	const [items, setItems] = React.useState([]);

	const [form] = Form.useForm();
	const yearId = Form.useWatch("yearId", form);
	const semesterId = Form.useWatch("semesterId", form);
	const date = Form.useWatch("date", form);
	const additionalFees = Form.useWatch("fees", form);
	const [studentIds, setStudentIds] = React.useState([]);

	useEffect(() => {
		fetchYearList();
		fetchFeeList();
	}, []);

	useEffect(() => {
		if (isVisible && years.length > 0) {
			form.setFieldsValue({
				yearId: context.year._id,
			});
		}
	}, [years, isVisible]);

	useEffect(() => {
		form.setFieldsValue({
			studentId: null,
			semesterId: null,
		});

		if (yearId) {
			fetchFeeList();
			setYear(years.find((year) => year._id === yearId));
		}
	}, [yearId]);

	useEffect(() => {
		form.setFieldValue("date", null);

		if (semesterId) {
			const semester = year.semesters.find((semester) => semester._id === semesterId);
			setSemester(semester);
			form.setFieldValue("date", dayjs(semester.startedAt));
		}
	}, [semesterId]);

	const fetchYearList = () => {
		onLoading(true);

		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchFeeList = () => {
		onLoading(true);
		requestGetFeeList({
			limit: 1000,
		})
			.then((response) => {
				setFees(response.data.data);
				updateMappedFee(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const updateMappedFee = (data) => {
		setFeeRows(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				type:
					d.type === "ONCE" ? (
						<Tag color="red">Sekali</Tag>
					) : d.type === "MONTHLY" ? (
						<Tag color="green">Bulanan</Tag>
					) : d.type === "SEMESTRIAL" ? (
						<Tag color="blue">Persemester</Tag>
					) : d.type === "PERIODICAL" ? (
						<Tag color="yellow">Periode</Tag>
					) : (
						<Tag>Lainnya</Tag>
					),
				nominal: Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(
					d.nominal
				),
				description: d.description,
			}))
		);
	};

	const handleClose = () => {
		form.resetFields();
		setStudentIds([]);
		setFees([]);
		onClose();
	};

	const handleSubmit = () => {
		if (items.length === 0) {
			notification["error"]({
				message: "Kesalahan!",
				description: "Jenis tagihan harus dipilih",
			});
			return;
		}
		form.validateFields().then((values) => {
			onLoading(true);

			values.studentIds = studentIds;
			values.date = dayjs(values.date).format("YYYY-MM-DD");
			values.items = items;
			values.fees = additionalFees
				? additionalFees.filter((fee) => fee && fee.nominal && fee.name)
				: [];

			requestGenerateInvoices(values)
				.then((response) => {
					notification["success"]({
						message: "Good job!",
						description: response.data.message,
					});
					form.resetFields();
					setItems([]);
					setYear(null);
					setSemester(null);
					setStudentIds([]);
					setFees([]);
					onSuccess();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification["error"]({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification["error"]({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					onLoading(false);
				});
		});
	};

	const handleOnStudentsChange = (value) => {
		setStudentIds(value);
	};

	const handleItemsChange = (value) => {
		setItems(value);
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={1024}
				title={"Buat Tagihan Masal"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan membuat data tagihan secara masal?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Buat"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
							}}>
							Pembuatan tagihan secara masal memungkinkan Anda untuk membuat tagihan untuk seluruh
							siswa AKTIF. Pastikan Anda telah memilih tahun akademik dan semester yang sesuai
							dengan data siswa yang akan dibuatkan tagihan. Namun pada fungsi ini Anda tidak dapat
							mengubah data tagihan secara individu, Anda hanya dapat mengubah data tagihan secara
							masal.
						</Typography.Text>
						<Divider />
						<Row gutter={[12, 12]}>
							<Col span={semesterId && semester ? 8 : 12}>
								<Form.Item
									style={{ marginTop: 0, marginBottom: 0 }}
									name="yearId"
									label="Thn Akademik"
									required
									rules={[
										{
											required: true,
											message: "Tahun akademik harus dipilih",
										},
									]}
									tooltip="Pilih tahun akademik">
									<Select
										showSearch
										placeholder="Pilih tahun akademik"
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}>
										{years.map((year) => (
											<Select.Option key={year._id} value={year._id}>
												{`${year.name}${year._id === context.year._id ? " (Aktif)" : ""}`}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							{year && (
								<Col span={semesterId && semester ? 8 : 12}>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 0 }}
										name="semesterId"
										label="Semester"
										required
										rules={[
											{
												required: true,
												message: "Semester harus dipilih",
											},
										]}
										tooltip="Pilih semester yang akan diproses">
										<Select
											showSearch
											placeholder="Pilih semester"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{year.semesters.map((semester) => (
												<Select.Option key={semester._id} value={semester._id}>
													{`${semester.name} (${dayjs(semester.startedAt).format(
														"DD MMM YYYY"
													)} - ${dayjs(semester.endedAt).format("DD MMM YYYY")})`}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							)}
							{semesterId && semester && (
								<Col span={8}>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 0 }}
										name="date"
										label="Tgl Tagihan"
										required
										rules={[
											{
												required: true,
												message: "Tanggal tagihan harus dipilih",
											},
										]}
										tooltip="Anda dapat mengirim tagihan di atas tanggal 3 hingga tanggal 28">
										<DatePicker
											style={{ width: "100%" }}
											format={"DD MMM YYYY"}
											disabledDate={(current) => {
												const tooEarly = current.isBefore(
													dayjs(semester.startedAt).toDate(),
													"day"
												);
												const tooLate = current.isAfter(dayjs(semester.endedAt).toDate(), "day");
												const firstDays = current.date() < 2;
												const threeLastDays = current.date() > 28;
												return tooEarly || tooLate || firstDays || threeLastDays;
											}}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
							}}>
							Tanggal tagihan dapat diaplikasikan pada <strong>tanggal 2 hingga 28</strong> setiap
							bulannya. Pilih tanggal tagihan yang sesuai dengan kebijakan sekolah. Tanggal tagihan
							berperan penting dalam proses pengecekan, pastikan memilih tanggal tagihan dengan
							benar. Penarikan biaya dengan tipe <strong>BULANAN</strong> membutuhkan tanggal pada
							bulan yang akan ditarik. Misalnya bila Anda memilih tanggal 1 Januari, maka sistem{" "}
							<strong>hanya akan</strong> menarik tagihan bulan Januari,{" "}
							<strong>tidak termasuk</strong> bulan Desember atau Februari.
						</Typography.Text>
						<Divider />
						{year && semester && date && (
							<>
								<Typography.Paragraph
									style={{
										marginTop: 12,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Jenis Tagihan
									<Tooltip
										placement="top"
										title={"Pilih jenis tagihan yang akan diberikan kepada siswa terpilih"}>
										<QuestionCircleOutlined
											style={{
												marginLeft: 4,
												color: "rgba(0, 0, 0, 0.45)",
											}}
										/>
									</Tooltip>
								</Typography.Paragraph>
								<InvoiceItemInput
									year={year}
									semester={semester}
									date={date.format("YYYY-MM-DD")}
									fees={fees}
									onChange={handleItemsChange}
								/>
								<Typography.Paragraph
									style={{
										marginTop: 24,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Biaya Tambahan
									<Tooltip
										placement="top"
										title={"Biaya tambahan yang akan diaplikasikan ke seluruh tagihan"}>
										<QuestionCircleOutlined
											style={{
												marginLeft: 4,
												color: "rgba(0, 0, 0, 0.45)",
											}}
										/>
									</Tooltip>
								</Typography.Paragraph>
								<Form.List name="fees">
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, ...restField }) => (
												<Row
													key={key}
													gutter={12}
													style={{
														display: "flex",
														width: "100%",
														marginBottom: 12,
													}}>
													<Col flex={1}>
														<Form.Item
															{...restField}
															name={[name, "name"]}
															rules={[
																{
																	required: true,
																	message: "Nama wajib diisi",
																},
															]}
															style={{
																marginBottom: 0,
															}}>
															<Input
																placeholder="Nama"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col flex={1}>
														<Form.Item
															{...restField}
															name={[name, "nominal"]}
															rules={[
																{
																	required: true,
																	message: "Nominal wajib diisi",
																},
															]}
															style={{
																marginBottom: 0,
															}}>
															<InputNumber
																addonBefore="Rp"
																controls={false}
																min={0}
																placeholder="Nominal"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col flex="none" style={{ alignSelf: "center" }}>
														<MinusCircleOutlined
															style={{
																color: "red",
															}}
															onClick={() => remove(name)}
														/>
													</Col>
												</Row>
											))}
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Tambah
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
								{/* <Typography.Paragraph
									style={{
										marginTop: 24,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Siswa Terpilih
									<Tooltip placement="top" title={"Pilih siswa yang akan ditagih"}>
										<QuestionCircleOutlined
											style={{
												marginLeft: 4,
												color: "rgba(0, 0, 0, 0.45)",
											}}
										/>
									</Tooltip>
								</Typography.Paragraph>
								<InvoiceStudentInput yearId={year._id} onChange={handleOnStudentsChange} /> */}
								<Typography.Text
									type="secondary"
									style={{
										display: "block",
									}}>
									Tagihan akan dibuat untuk seluruh siswa AKTIF sesuai dengan jenis tagihan yang
									dipilih. Bila siswa sudah memiliki tagihan yang dipilih, maka tagihan tidak akan
									dibuat ulang.
								</Typography.Text>
								<Form.Item name="isSent" valuePropName="checked">
									<Checkbox>
										Apakah tagihan langsung dikirim ke siswa? Tagihan akan berstatus{" "}
										<strong>TERKIRIM</strong> dan siswa akan menerima tagihan
									</Checkbox>
								</Form.Item>
							</>
						)}
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default InvoiceGenerateFormModal;
