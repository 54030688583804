/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, Modal, App, Popconfirm, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { addTeacher, editTeacher } from "../../../../services/admin.service";
import moment from "moment";

const TeacherFormModal = ({ teacher, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { message, notification, modal } = App.useApp();

	const [form] = Form.useForm();

	useEffect(() => {
		if (teacher !== null) {
			form.setFieldsValue({
				number: teacher.number,
				name: teacher.name,
				birthPlace: teacher.birthPlace,
				birthDate: moment(teacher.birthDate, "YYYY-MM-DD"),
				address: teacher.address,
				gender: teacher.gender,
			});
		}
	}, [teacher]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (teacher) {
					values.id = teacher._id;
					editTeacher(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addTeacher(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={teacher === null || teacher === undefined ? "Tambah Guru" : "Ubah Guru"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data guru?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{teacher ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="number"
							label="Nomor Induk"
							required
							rules={[
								{
									required: true,
									message: "Masukkan nomor induk guru",
								},
							]}
							tooltip="Masukkan nomor induk guru">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[
								{
									required: true,
									message: "Masukkan nama guru",
								},
							]}
							tooltip="Masukkan nama guru">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="birthPlace"
							label="Tempat Lahir"
							tooltip="Masukkan tempat lahir guru">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="birthDate"
							label="Tanggal Lahir"
							tooltip="Masukkan tanggal lahir guru">
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="address"
							label="Alamat"
							tooltip="Masukkan alamat guru">
							<Input.TextArea style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="gender"
							label="Jenis Kelamin"
							required
							rules={[
								{
									required: true,
									message: "Pilih jenis kelamin guru",
								},
							]}
							tooltip="Pilih jenis kelamin guru">
							<Select style={{ width: "100%" }}>
								<Select.Option value="MALE">Laki-laki</Select.Option>
								<Select.Option value="FEMALE">Perempuan</Select.Option>
							</Select>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default TeacherFormModal;
