import axios from "axios";

// YEAR
export async function requestGetYearList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/years?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGetActiveYear() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/years/active`);
}
// YEAR

// CLASSROOM
export async function requestGetClassroomList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/classrooms?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowClassroom(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/classrooms/${id}`);
}
// CLASSROOM

// LESSON
export async function requestGetLessonList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/lessons?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function showLesson(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}`);
}
// LESSON

// STUDENT
export async function unenrollStudents(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/students/unenroll`, data);
}
// STUDENT

// EVENT
export async function requestGetEventList(filter = "") {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/teacher/events?order=startedAt:asc&filter=${filter}`
	);
}

export async function requestShowEvent(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/events/${id}`);
}

export async function requestAddEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/add`, data);
}

export async function requestReportEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/report`, data);
}
// EVENT
