import { Button, InputNumber, notification, Space } from "antd";
import { useState } from "react";
import { requestEditReportMark } from "../../../../services/admin.service";
import { CheckOutlined } from "@ant-design/icons";

function ReportCardDetailTableCell({ markId, assessmentId, score, onSaved }) {
	const [newScore, setNewScore] = useState(score);
	const [isSaving, setIsSaving] = useState(false);

	const handleSaveMarkButton = () => {
		setIsSaving(true);
		requestEditReportMark(markId, {
			assessmentId: assessmentId,
			score: newScore ?? 0,
		})
			.then((response) => {
				notification["success"]({
					message: "Good job!",
					description: response.data.message,
				});

				onSaved();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	return (
		<Space>
			<InputNumber
				size="small"
				min={0}
				max={100}
				onChange={(value) => setNewScore(value)}
				value={newScore}
			/>
			<Button
				type={score === newScore ? "default" : "primary"}
				loading={isSaving}
				size="small"
				onClick={() => {
					if (score === newScore) {
						return;
					}
					handleSaveMarkButton();
				}}>
				<CheckOutlined />
			</Button>
		</Space>
	);
}

export default ReportCardDetailTableCell;
