/* eslint-disable react-hooks/exhaustive-deps */
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteFilled,
	EditFilled,
	PlusCircleFilled,
	ExclamationCircleOutlined,
	ExclamationCircleFilled,
	MoreOutlined,
} from "@ant-design/icons";
import {
	Button,
	Card,
	Col,
	App,
	Popconfirm,
	Row,
	Space,
	Table,
	Tag,
	Typography,
	Modal,
	Dropdown,
} from "antd";
import React, { useEffect, useState } from "react";
import { deleteYear, requestGetYearList } from "../../../services/admin.service";
import YearFormModal from "./components/YearFormModal";
import dayjs from "dayjs";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const { Title } = Typography;
const { confirm } = Modal;

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Tanggal Mulai",
		dataIndex: "startedAt",
		key: "startedAt",
		sorter: true,
	},
	{
		title: "Tanggal Berakhir",
		dataIndex: "endedAt",
		key: "endedAt",
		sorter: true,
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "edit",
		label: "Edit",
		icon: <HiOutlinePencil />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const YearPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [years, setYears] = useState([]);
	const [mappedYears, setMappedYears] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [year, setYear] = useState(null);
	const [isYearFormVisible, setYearFormVisible] = useState(false);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchYearList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchYearList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetYearList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setYears(response.data.data);
				updateMappedYear(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onDeleteButtonClick = (id) => {
		confirm({
			title: "Apakah anda yakin?",
			icon: <ExclamationCircleOutlined />,
			content:
				"Anda yakin ingin menghapus tahun akademik ini? Menghapus tahun akademik akan menghapus semua data yang terkait dengan tahun akademik ini. Termasuk pelajaran, jadwal, dan siswa yang terdaftar pada tahun ajaran ini. Anda tidak dapat mengembalikan data yang telah dihapus, pertimbangkan dengan baik sebelum menghapus data.",
			okText: "Ya",
			okType: "danger",
			cancelText: "Tidak",
			onOk() {
				handleDeleteButton(id);
			},
			onCancel() {},
		});
	};

	const updateMappedYear = (data) => {
		setMappedYears(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				startedAt: dayjs(d.startedAt).format("DD MMM YYYY"),
				endedAt: dayjs(d.endedAt).format("DD MMM YYYY"),
				status: (
					<>
						{d.status === "ACTIVE" ? (
							<Tag style={{ marginRight: 0 }} icon={<CheckCircleOutlined />} color="success">
								Aktif
							</Tag>
						) : (
							<Tag style={{ marginRight: 0 }} icon={<CloseCircleOutlined />} color="error">
								Non-aktif
							</Tag>
						)}
					</>
				),
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus tahun ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const expandedYearRender = (row) => {
		const columns = [
			{
				title: "Nama",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "Tanggal Mulai",
				dataIndex: "startedAt",
				key: "startedAt",
			},
			{
				title: "Tanggal Akhir",
				dataIndex: "endedAt",
				key: "endedAt",
			},
		];

		const data = [];
		years
			.find((year) => year._id === row.key)
			.semesters.forEach((semester) => {
				data.push({
					key: semester._id,
					name: semester.name,
					startedAt: dayjs(semester.startedAt).format("DD MMM YYYY"),
					endedAt: dayjs(semester.endedAt).format("DD MMM YYYY"),
				});
			});

		return (
			<Table
				title={() => (
					<Row justify="space-between">
						<Title style={{ marginBottom: 0 }} level={5}>
							Daftar Semester
						</Title>
					</Row>
				)}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};

	const handleAddButton = () => {
		setYearFormVisible(true);
	};

	const handleEditButton = (year) => {
		setYear(year);
		setYearFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		setLoading(true);
		deleteYear(id)
			.then((response) => {
				if (response.data.code === 200) {
					notification["success"]({
						message: "Good job!",
						description: response.data.message,
					});

					fetchYearList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseYearForm = () => {
		setYear(null);
		setYearFormVisible(false);
	};

	const handleOnSuccessYearForm = () => {
		setYear(null);
		setYearFormVisible(false);
		fetchYearList();
	};

	return (
		<>
			<YearFormModal
				isLoading={isLoading}
				isVisible={isYearFormVisible}
				onLoading={(v) => setLoading(v)}
				year={year}
				onClose={handleOnCloseYearForm}
				onSuccess={handleOnSuccessYearForm}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Tahun Akademik"
							extra={
								<Space>
									<SearchInput
										placeholder="Nama tahun akademik"
										onSearch={handleSearch}
										defaultValue={keyword}
										isLoading={isLoading}
									/>
									<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
										<span className="ant-btn-text">Tambah</span>
									</Button>
								</Space>
							}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedYears}
									expandedRowRender={expandedYearRender}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default YearPage;
