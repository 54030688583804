/* eslint-disable react-hooks/exhaustive-deps */
import {
	App,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Form,
	Input,
	Modal,
	Popconfirm,
	Row,
	Select,
	Space,
	Steps,
	Table,
	Tabs,
	TimePicker,
	Typography,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { requestScheduleLesson, requestShowLesson } from "../../../../services/admin.service";
import dayjs from "dayjs";
import LessonReportFormModal from "./LessonReportFormModal";

const studentColumns = [
	{
		title: "Nomor Induk",
		dataIndex: "number",
		key: "number",
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
	},
];

const LessonDetailModal = ({
	activeYear,
	id,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();
	const [mappedStudents, setMappedStudents] = React.useState([]);
	const [lesson, setLesson] = React.useState(null);
	const [semesterTimes, setSemesterTimes] = React.useState([]);
	const [requireRefresh, setRequireRefresh] = React.useState(false);
	const [selectedMonth, setSelectedMonth] = React.useState(null);

	const [isReportLessonVisible, setReportLessonVisible] = React.useState(false);
	const [event, setEvent] = React.useState(null);

	useEffect(() => {
		if (isVisible && id) {
			fetchLessonDetail();
		} else {
			setLesson(null);
		}
	}, [isVisible, id]);

	const fetchLessonDetail = () => {
		onLoading(true);
		requestShowLesson(id)
			.then((response) => {
				const lesson = response.data.data;
				const students = lesson.students;
				const mappedStudents = students.map((student) => {
					return {
						key: student._id,
						number: student.number,
						name: student.name,
					};
				});
				setMappedStudents(mappedStudents);

				setLesson(lesson);
				const events = lesson.events;
				const semesters = lesson.year.semesters;

				const semesterTimes = [];

				// group events by time and semesters
				semesters.forEach((semester) => {
					events.forEach((event) => {
						if (
							dayjs(event.startedAt).toDate() >= dayjs(semester.startedAt).toDate() &&
							dayjs(event.startedAt).toDate() <= dayjs(semester.endedAt).toDate()
						) {
							// get day from event
							const day = dayjs(event.startedAt).day();
							// get time from event
							const startedAt = dayjs(event.startedAt).format("HH:mm");
							const endedAt = dayjs(event.endedAt).format("HH:mm");

							// concat
							const time = `${day}-${startedAt}-${endedAt}`;

							// group by day and time
							if (!semesterTimes[semester._id]) {
								semesterTimes[semester._id] = [];
							}
							if (
								!semesterTimes[semester._id]
									.map((s) => `${s.day}-${s.startedAt}-${s.endedAt}`)
									.includes(time)
							) {
								semesterTimes[semester._id].push({
									day: day,
									startedAt: startedAt,
									endedAt: endedAt,
								});
							}
						}
					});
				});

				setSemesterTimes(semesterTimes);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleReportButton = (event) => {
		setEvent(event);
		setReportLessonVisible(true);
	};

	const handleSubmit = (semesterId, values) => {
		onLoading(true);
		if (values.remaining) {
			values.mode = "REMAINING";
		}
		values.type = "LESSON";
		values.referenceId = lesson._id;
		values.semesterId = semesterId;
		values.times = values.times.map((time) => {
			return {
				day: time.day,
				startedAt: dayjs(time.time[0]).format("HH:mm"),
				endedAt: dayjs(time.time[1]).format("HH:mm"),
			};
		});

		requestScheduleLesson(lesson._id, values)
			.then((response) => {
				notification["success"]({
					message: "Good job!",
					description: response.data.message,
				});
				fetchLessonDetail();
				setRequireRefresh(true);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		onClose(requireRefresh);
	};

	const handleOnReportSuccess = (lesson, event) => {
		const tempLesson = { ...lesson };
		const tempEvents = [...tempLesson.events];
		const index = tempEvents.findIndex((e) => e._id === event._id);
		if (index !== -1) {
			tempEvents[index] = event;
			tempLesson.events = tempEvents;
			setLesson(tempLesson);
		}
	};

	return (
		<>
			<LessonReportFormModal
				isVisible={isReportLessonVisible}
				onClose={() => {
					setReportLessonVisible(false);
				}}
				onSuccess={handleOnReportSuccess}
				lesson={lesson}
				event={event}
				onLoading={onLoading}
				isLoading={isLoading}
			/>
			<Modal
				maskClosable={false}
				width={1024}
				title={"Detail Pelajaran"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				{lesson && (
					<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
						<Col span={24}>
							<Space direction="vertical">
								<Typography.Title level={3} style={{ margin: 0 }}>
									{lesson.subject.name} - {lesson.classroom.name}
								</Typography.Title>
							</Space>
						</Col>
						<Col lg={16} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Detail" size="small">
									<Descriptions layout="vertical" column={2}>
										<Descriptions.Item label="Mata Pelajaran">
											{lesson.subject.name}
										</Descriptions.Item>
										<Descriptions.Item label="Kelas">
											{lesson.classroom.grade} ({lesson.classroom.name})
										</Descriptions.Item>
										<Descriptions.Item label="Tahun Ajaran">{lesson.year.name}</Descriptions.Item>
										<Descriptions.Item label="Total Jam">
											{lesson.totalHour ?? 0} Jam
										</Descriptions.Item>
									</Descriptions>
								</Card>
								<Card title="Siswa" size="small">
									<Table
										size="small"
										columns={studentColumns}
										dataSource={mappedStudents}
										loading={isLoading}
										bordered
										pagination={{
											position: "bottomRight",
											pageSizeOptions: ["10", "20", "50", "100"],
											showSizeChanger: true,
											locale: { items_per_page: "" },
										}}
										className="ant-border-space"
									/>
								</Card>
							</Space>
						</Col>
						<Col lg={8} md={24}>
							<Space
								direction="vertical"
								size="middle"
								className="layout-popup"
								style={{ width: "100%" }}>
								<Card title="Sinkronisasi Jadwal" size="small">
									<Tabs
										type="line"
										size="small"
										items={lesson.year.semesters.map((semester) => {
											return {
												label: `${semester.name}`,
												key: semester._id,
												children: (
													<SemesterForm
														onSubmit={(values) => handleSubmit(semester._id, values)}
														times={semesterTimes[semester._id]}
													/>
												),
											};
										})}
									/>
								</Card>
								<Card
									title={
										<Row justify="space-between">
											Pertemuan{" "}
											<DatePicker
												size="small"
												picker="month"
												placeholder="Pilih bulan"
												onChange={(v) => {
													setSelectedMonth(v);
												}}
											/>
										</Row>
									}
									size="small">
									<Space style={{ width: "100%" }} direction="vertical">
										<Steps
											progressDot
											size="small"
											direction="vertical"
											current={
												lesson.events.filter(
													(event) =>
														dayjs(event.startedAt).format("MM-YYYY") ===
															dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
												).length - 1
											}>
											{lesson.events &&
												lesson.events
													.filter(
														(event) =>
															dayjs(event.startedAt).format("MM-YYYY") ===
																dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
													)
													.map((event) => (
														<Steps.Step
															key={event._id}
															title={dayjs(event.startedAt).format("dddd, D MMMM YYYY")}
															description={
																<span>
																	{dayjs(event.startedAt).format("HH:mm") +
																		" - " +
																		dayjs(event.endedAt).format("HH:mm")}
																	{dayjs(event.startedAt).toDate() < dayjs().toDate() && (
																		<Button
																			type="dashed"
																			style={{ marginLeft: 4 }}
																			size="small"
																			onClick={() => {
																				handleReportButton(event);
																			}}>
																			Laporan
																		</Button>
																	)}
																</span>
															}
														/>
													))}
										</Steps>
									</Space>
								</Card>
							</Space>
						</Col>
					</Row>
				)}
			</Modal>
		</>
	);
};

export default LessonDetailModal;

export function SemesterForm({ times, onSubmit }) {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			onSubmit(values);
		});
	};

	useEffect(() => {
		if (times) {
			form.setFieldsValue({
				times: times.map((time) => {
					return {
						day: time.day.toString(),
						time: [dayjs(time.startedAt, "HH:mm"), dayjs(time.endedAt, "HH:mm")],
					};
				}),
			});
		}
	}, [times]);

	return (
		<Form form={form} layout="vertical" requiredMark="optional">
			<Form.List name="times" label="Bahan" style={{ marginTop: 0, marginBottom: 12 }}>
				{(times, { add, remove }) => (
					<>
						{times.map(({ key, name, ...restField }, itemIndex) => (
							<Row gutter={8} key={key} align="baseline">
								<Col span={8}>
									<Form.Item
										required
										style={{ marginTop: 0, marginBottom: 12 }}
										{...restField}
										name={[name, "day"]}
										rules={[
											{
												required: true,
												message: "Nama harus diisi!",
											},
										]}>
										<Select placeholder="Hari">
											<Select.Option value="0">Ahad</Select.Option>
											<Select.Option value="1">Senin</Select.Option>
											<Select.Option value="2">Selasa</Select.Option>
											<Select.Option value="3">Rabu</Select.Option>
											<Select.Option value="4">Kamis</Select.Option>
											<Select.Option value="5">Jumat</Select.Option>
											<Select.Option value="6">Sabtu</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item requiredMark style={{ marginTop: 0, marginBottom: 0 }} {...restField}>
										<Input.Group compact>
											<Form.Item
												requiredMark
												name={[name, "time"]}
												style={{
													width: "calc(100% - 32px)",
													marginTop: 0,
													marginBottom: 0,
												}}
												rules={[
													{
														required: true,
														message: "Waktu harus diisi ya!",
													},
												]}>
												<TimePicker.RangePicker
													format={"HH:mm"}
													style={{
														width: "100%",
														marginTop: 0,
														marginBottom: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												/>
											</Form.Item>
											<Button
												icon={<DeleteOutlined />}
												onClick={() => remove(name)}
												danger></Button>
										</Input.Group>
									</Form.Item>
								</Col>
							</Row>
						))}
						<Form.Item style={{ marginTop: 0, marginBottom: 12 }}>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Tambah Jam
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item name="remaining" valuePropName="checked" initialValue={true}>
				<Checkbox value={"remaining"}>Ganti sisa jadwal saja (mulai hari ini)</Checkbox>
			</Form.Item>
			<Popconfirm
				placement="topRight"
				title={`Semua data yang berkaitan (termasuk laporan) dengan jadwal yang terhapus akan ikut terhapus. Apakah anda yakin?`}
				onConfirm={handleSubmit}
				okText="Yes"
				cancelText="No">
				<Button type="primary" style={{ width: "100%" }}>
					Terapkan
				</Button>
			</Popconfirm>
		</Form>
	);
}
