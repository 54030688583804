/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	CopyFilled,
	ExclamationCircleFilled,
	MoreOutlined,
	PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Card, Col, Dropdown, App, Row, Space, Table, Tag, Segmented, theme } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
	requestGetClassroomList,
	requestGetLessonList,
	requestGetYearList,
} from "../../../services/teacher.service";
import AppContext from "../../../store/app.context";
import LessonDetailModal from "./components/LessonDetailModal";
import { YearFilter } from "../../../components/YearFilter";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import LessonReportFormModal from "./components/LessonReportFormModal";
import SearchInput from "../../../components/SearchInput";

const { useToken } = theme;

const columns = [
	{
		title: "Tahun Akademik",
		dataIndex: "year",
		key: "year",
	},
	{
		title: "Matpel",
		dataIndex: "subject",
		key: "subject",
	},
	{
		title: "Kelas",
		dataIndex: "classroom",
		key: "classroom",
	},
	{
		title: "Total Jam",
		dataIndex: "totalHour",
		key: "totalHour",
	},
	{
		title: "Jumlah Siswa",
		dataIndex: "studentCount",
		key: "studentCount",
	},
	{
		title: "Jumlah Guru",
		dataIndex: "teacherCount",
		key: "teacherCount",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const LessonPage = () => {
	const { message, notification, modal } = App.useApp();

	const context = useContext(AppContext);
	const { token } = useToken();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [lessons, setLessons] = useState([]);
	const [mappedLessons, setMappedLessons] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [lesson, setLesson] = useState(null);
	const [isLessonFormVisible, setLessonFormVisible] = useState(false);
	const [isLessonDetailVisible, setLessonDetailVisible] = useState(false);
	const [isLessonCloneFormVisible, setLessonCloneFormVisible] = useState(false);

	const [years, setYears] = useState([]);
	const [classrooms, setClassrooms] = useState([]);

	const [filteredBy, setFilteredBy] = useState("");
	const [selectedYearId, setSelectedYearId] = useState("");
	const [selectedClassroom, setSelectedClassroom] = useState("");
	const [selectedClassroomId, setSelectedClassroomId] = useState("");

	useEffect(() => {
		setSelectedYearId(context.year._id);
		fetchYearList();
		fetchClassroomList();
	}, []);

	useEffect(() => {
		if (filter.year) {
			fetchLessonList();
		}
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (selectedYearId !== "") {
			const year = years.find((year) => year._id === selectedYearId);
			if (year) {
				setFilteredBy(year.name);
			} else {
				setFilteredBy(context.year.name);
			}
		} else {
			setFilteredBy("");
		}
		setFilter({
			...filter,
			year: selectedYearId,
			classroom: selectedClassroomId,
		});
	}, [selectedYearId, selectedClassroomId]);

	const fetchLessonList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetLessonList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setLessons(response.data.data);
				updateMappedLesson(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchYearList = () => {
		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {});
	};

	const fetchClassroomList = () => {
		requestGetClassroomList({
			page: 1,
			limit: 1000,
		})
			.then((response) => {
				setClassrooms(response.data.data);
			})
			.catch((error) => {});
	};

	const otherButtons = [
		{
			key: "show",
			label: "Lihat",
			icon: <HiOutlineEye />,
		},
		{
			key: "edit",
			label: "Edit",
			icon: <HiOutlinePencil />,
		},
		{
			type: "divider",
		},
		{
			key: "delete",
			label: "Hapus",
			icon: <HiOutlineTrash />,
			danger: true,
		},
	];

	const updateMappedLesson = (data) => {
		setMappedLessons(
			data.map((d) => ({
				key: d._id,
				year: (
					<>
						{d.year.name}{" "}
						{context.year && d.year._id === context.year._id && <Tag color="green">Aktif</Tag>}
					</>
				),
				subject: `${d.subject.name}`,
				classroom: `${d.classroom.name}`,
				totalHour: d.totalHour ?? 0,
				studentCount: d.students.length,
				teacherCount: d.teachers.filter((teacher) => teacher.type !== "GUIDANCE").length,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "show") {
										handleShowButton(d);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setLessonFormVisible(true);
	};

	const handleEditButton = (lesson) => {
		setLesson(lesson);
		setLessonFormVisible(true);
	};

	const handleShowButton = (lesson) => {
		setLesson(lesson);
		setLessonDetailVisible(true);
	};

	const handleOnCloseLessonForm = () => {
		setLesson(null);
		setLessonFormVisible(false);
	};

	const handleOnSuccessLessonForm = () => {
		setLesson(null);
		setLessonFormVisible(false);
		fetchLessonList();
	};

	const handleOnCloseLessonDetail = (requireRefresh) => {
		setLesson(null);
		setLessonDetailVisible(false);
		if (requireRefresh) {
			fetchLessonList();
		}
	};

	const handleOnSuccessLessonDetail = () => {
		setLesson(null);
		setLessonDetailVisible(false);
		fetchLessonList();
	};

	const handleCloneButton = () => {
		setLessonCloneFormVisible(true);
	};

	const handleOnCloseLessonCloneForm = () => {
		setLessonCloneFormVisible(false);
	};

	const handleOnSuccessLessonCloneForm = () => {
		setLessonCloneFormVisible(false);
		fetchLessonList();
	};

	return (
		<>
			<LessonDetailModal
				isLoading={isLoading}
				isVisible={isLessonDetailVisible}
				onLoading={(v) => setLoading(v)}
				id={lesson ? lesson._id : null}
				onClose={handleOnCloseLessonDetail}
				onSuccess={handleOnSuccessLessonDetail}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Pelajaran"
							extra={
								<Space>
									<SearchInput
										placeholder="Matpel atau kelas"
										onSearch={handleSearch}
										defaultValue={keyword}
										isLoading={isLoading}
									/>
									<YearFilter
										activeYear={context.year}
										years={years}
										filteredBy={{
											key: selectedYearId,
											value: filteredBy,
										}}
										filterBy={(key) => {
											setSelectedYearId(key);
										}}
									/>
								</Space>
							}>
							<div>
								<Space direction="vertical" style={{ margin: token.margin }}>
									<Segmented
										size="large"
										options={[
											{ label: "Semua", value: "" },
											...classrooms.reduce((acc, classroom) => {
												if (!acc.find((item) => item.value === classroom.grade)) {
													acc.push({
														label: classroom.grade,
														value: classroom.grade,
													});
												}
												return acc;
											}, []),
										]}
										onChange={(e) => setSelectedClassroom(e)}
									/>
									<Segmented
										size="large"
										options={[
											{ label: "Semua", value: "" },
											...classrooms
												.filter(
													(classroom) => classroom.grade === selectedClassroom || !selectedClassroom
												)
												.map((classroom) => {
													return {
														label: classroom.name,
														value: classroom._id,
													};
												}),
										]}
										onChange={(e) => {
											setSelectedClassroomId(e);
										}}
									/>
								</Space>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedLessons}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-lesson"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default LessonPage;
