/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	CreditCardFilled,
	CreditCardOutlined,
	CreditCardTwoTone,
	MoreOutlined,
	PlusCircleFilled,
	QrcodeOutlined,
} from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Switch, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
	requestBlockPocket,
	requestGetPocketList,
	requestUnblockPocket,
} from "../../../services/admin.service";
import { HiCreditCard, HiOutlineCreditCard, HiOutlineEye, HiQrCode } from "react-icons/hi2";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import PocketWithdrawalFormModal from "./components/PocketWithdrawalFormModal";
import PocketDetailModal from "./components/PocketDetailModal";
import PocketScannerModal from "./components/PocketScannerModal";
import { Navigate, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
		render: (text, record) => (
			<Space direction="horizontal">
				<CreditCardFilled
					style={{
						fontSize: "4.5em",
						color: `${record.type === "SMARTIVA" ? "gold" : "silver"}`,
						verticalAlign: "middle",
					}}
				/>
				<Space direction="vertical">
					<Typography.Text>{record.name}</Typography.Text>
					<Typography.Text
						style={{ margin: 0 }}
						copyable={{ text: record.number, tooltips: "Salin nomor" }}
						strong>
						{record.number.replace(/(.{4})/g, "$1-").slice(0, -1)}
					</Typography.Text>
				</Space>
			</Space>
		),
	},
	{
		title: "Tipe",
		dataIndex: "type",
		key: "type",
		render: (text, record) => (
			<Tag style={{ fontWeight: "bold" }} color={record.type === "SMARTIVA" ? "gold" : "default"}>
				{record.type === "SMARTIVA" ? "SMARTIVA" : "SEKOLAH"}
			</Tag>
		),
	},
	{
		title: "Siswa",
		dataIndex: "student",
		key: "student",
		render: (text, record) => (
			<Space>
				<Typography.Text>{record.student.name}</Typography.Text>
				<Tag color="blue">
					<Typography.Text
						strong
						copyable={{ text: record.student.number, tooltips: "Salin nomor" }}>
						{record.student.number}
					</Typography.Text>
				</Tag>
			</Space>
		),
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		filters: [
			{
				text: "ENABLED",
				value: "ENABLED",
			},
			{
				text: "DISABLED",
				value: "DISABLED",
			},
		],
		render: (text, record) => (
			<Tag color={record.status === "ENABLED" ? "green" : "red"}>{record.status}</Tag>
		),
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat Detail",
		icon: <HiOutlineEye />,
	},
	{
		key: "withdraw",
		label: "Tarik Saldo",
		icon: <HiOutlineCreditCard />,
	},
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <HiOutlineTrash />,
	// 	danger: true,
	// },
];

const PocketPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification } = App.useApp();

	const [pockets, setPockets] = useState([]);
	const [mappedPockets, setMappedPockets] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isBlocking, setBlocking] = useState(false);

	const [isDetailVisible, setDetailVisible] = useState(false);
	const [isWithdrawVisible, setWithdrawVisible] = useState(false);
	const [pocket, setPocket] = useState(null);

	const [isScannerVisible, setScannerVisible] = useState(false);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchPocketList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const handleWithdrawButton = (pocket) => {
		setPocket(pocket);
		setWithdrawVisible(true);
	};

	const handleWithdrawSuccess = () => {
		setWithdrawVisible(false);
		setPocket(null);
		fetchPocketList();
	};

	const handleWithdrawClose = () => {
		setWithdrawVisible(false);
		setPocket(null);
	};

	const handleDetailButton = (pocket) => {
		setPocket(pocket);
		setDetailVisible(true);
	};

	const handleDetailClose = () => {
		setDetailVisible(false);
		setPocket(null);
	};

	const handleScannerButton = () => {
		setScannerVisible(true);
	};

	const handleScannerScan = (result) => {
		if (result === null) {
			return;
		}
		if (result.length === 0) {
			return;
		}
		if (result[0].rawValue === null || result[0].rawValue === "") {
			return;
		}

		const studentId = result[0].rawValue;
		setKeyword(studentId);

		setScannerVisible(false);
	};

	const handleScannerCancel = () => {
		setScannerVisible(false);
	};

	const fetchPocketList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetPocketList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setPockets(response.data.data);
				updateMappedPocket(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedPocket = (data) => {
		setMappedPockets(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				number: d.number,
				type: d.type,
				status: d.status,
				student: d.student,
				blocked: d.blocked,
				actions: (
					<Space>
						<Switch
							className="switch-blocked"
							checkedChildren="Buka Blokir"
							unCheckedChildren="Blokir"
							loading={isBlocking}
							checked={d.blocked === true}
							onChange={(checked) => {
								setBlocking(true);
								if (checked) {
									requestBlockPocket(d._id)
										.then((response) => {
											notification["success"]({
												message: "Berhasil!",
												description: response.data.message,
											});

											fetchPocketList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								} else {
									requestUnblockPocket(d._id)
										.then((response) => {
											notification["success"]({
												message: "Berhasil!",
												description: response.data.message,
											});

											fetchPocketList();
										})
										.catch((error) => {
											if (error.response && error.response.data) {
												notification["error"]({
													message: "Kesalahan!",
													description: error.response.data.message,
												});
											} else {
												notification["error"]({
													message: "Kesalahan!",
													description: error.message,
												});
											}
										})
										.finally(() => {
											setBlocking(false);
										});
								}
							}}
						/>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										handleDetailButton(d);
									} else if (e.key === "withdraw") {
										handleWithdrawButton(d);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<PocketWithdrawalFormModal
				isVisible={isWithdrawVisible}
				pocket={pocket}
				onSuccess={handleWithdrawSuccess}
				onClose={handleWithdrawClose}
			/>
			<PocketDetailModal
				isVisible={isDetailVisible}
				id={pocket ? pocket._id : ""}
				onClose={handleDetailClose}
			/>
			<PocketScannerModal
				isVisible={isScannerVisible}
				onScan={handleScannerScan}
				onCancel={handleScannerCancel}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Rekening Tabungan"
							extra={
								<Space>
									<SearchInput
										placeholder="Nomor atau nama siswa"
										onSearch={handleSearch}
										defaultValue={keyword}
										isLoading={isLoading}
									/>
									<Button
										icon={<QrcodeOutlined />}
										type="primary"
										onClick={handleScannerButton}></Button>
									<Button
										icon={<PlusCircleFilled />}
										type="primary"
										onClick={() => {
											notification["info"]({
												message: "Info",
												description: "Fitur ini belum tersedia.",
											});
										}}>
										<span className="ant-btn-text">Tambah</span>
									</Button>
								</Space>
							}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedPockets}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default PocketPage;
