import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input, Card, Checkbox, Spin, App } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import AppContext from "../../store/app.context";

const { Title } = Typography;
const { Footer } = Layout;

const LoginPage = (props) => {
	const { message, notification, modal } = App.useApp();

	const context = useContext(AppContext);
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isRemembered, setRemembered] = useState(false);

	const rememberMeHandler = (checked) => {
		setRemembered(checked);
	};

	const emailChangeHandler = (event) => {
		setEmail(event.target.value);
	};

	const passwordChangeHandler = (event) => {
		setPassword(event.target.value);
	};

	const onSubmit = (values) => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				axios
					.post(process.env.REACT_APP_API_URL + "/auth/login", {
						email: values.email,
						password: values.password,
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Halo, selamat datang ^_^");

							context.login(response.data.data.role);
							navigate("/dashboard");
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification["error"]({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const onError = (errorInfo) => {};

	return (
		<>
			{isLoading && <Spin className="container-spin" size="large" />}
			<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={8}>
					<Card style={{ margin: "24px" }}>
						<Title level={4}>Login</Title>
						<p>Untuk melanjutkan Anda harus login dulu ya</p>
						<Form
							form={form}
							className="login-form"
							initialValues={{ remember: true }}
							onFinishFailed={onError}
							onFinish={onSubmit}>
							<Form.Item
								onChange={emailChangeHandler}
								name="email"
								rules={[{ required: true, message: "Masukkan email Anda" }]}>
								<Input
									size="large"
									prefix={<MailOutlined className="site-form-item-icon" />}
									placeholder="Email"
								/>
							</Form.Item>
							<Form.Item
								name="password"
								onChange={passwordChangeHandler}
								rules={[{ required: true, message: "Masukkan password Anda" }]}>
								<Input.Password
									size="large"
									prefix={<LockOutlined className="site-form-item-icon" />}
									type="password"
									placeholder="Password"
								/>
							</Form.Item>
							<Form.Item>
								<Form.Item
									name="remember"
									valuePropName="checked"
									noStyle
									onChange={rememberMeHandler}>
									<Checkbox>Remember me</Checkbox>
								</Form.Item>

								<Link className="login-form-forgot" to={"/forgot-password"}>
									Forgot password
								</Link>
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit" className="login-form-button" size="large">
									Log in
								</Button>
							</Form.Item>
							<p className="font-semibold text-muted text-center">
								Belum punya akun?{" "}
								<Link to="/register" className="font-bold text-dark">
									Daftar
								</Link>
							</p>
						</Form>
						<Footer style={{ marginTop: 24 }}>
							<p className="copyright auth">
								Copyright © {new Date().getFullYear()} Smartiva by
								<a href="https://codeiva.com">Codeiva</a>.
							</p>
						</Footer>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default LoginPage;
