/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleFilled, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
	Badge,
	Button,
	Calendar,
	Card,
	Col,
	App,
	Popconfirm,
	Row,
	Space,
	Tag,
	Timeline,
	Typography,
	Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { requestGetEventList } from "../../../services/teacher.service";
import EventFormModal from "./components/EventFormModal";
import "./index.css";
import dayjs from "dayjs";
import LessonReportFormModal from "../lessons/components/LessonReportFormModal";

const EventPage = () => {
	const { message, notification, modal } = App.useApp();

	const [events, setEvents] = useState([]);
	const [selectedDateEvents, setSelectedDateEvents] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [date, setDate] = useState(() => dayjs());
	const [selectedDate, setSelectedDate] = useState(() => dayjs());

	const [month, setMonth] = useState(() => dayjs());

	const [event, setEvent] = useState(null);
	const [isEventFormVisible, setEventFormVisible] = useState(false);

	const [lesson, setLesson] = useState(null);
	const [isLessonReportVisible, setLessonReportVisible] = useState(false);

	useEffect(() => {
		fetchEventList();
	}, []);

	useEffect(() => {
		fetchEventList();
	}, [month]);

	useEffect(() => {
		mapEventsPerTime();
	}, [events]);

	useEffect(() => {
		mapEventsPerTime();
	}, [selectedDate]);

	const mapEventsPerTime = () => {
		const startedAt = dayjs(selectedDate).format("YYYY-MM-DD");
		const filteredEvents = events.filter(
			(event) => dayjs(event.startedAt).format("YYYY-MM-DD") === startedAt
		);

		// group filtered events by start time
		const groupedEvents = filteredEvents.reduce((acc, event) => {
			const key = dayjs(event.startedAt).format("HH:mm");
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(event);
			return acc;
		}, {});

		setSelectedDateEvents(groupedEvents);
	};

	const fetchEventList = () => {
		setLoading(true);
		requestGetEventList(
			`startedAt:${dayjs(month).startOf("month").format("YYYY-MM-DD")},endedAt:
			${dayjs(month).endOf("month").format("YYYY-MM-DD")},type:LESSON|EXTRA|OTHER`
		)
			.then((response) => {
				setEvents(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleAddButton = () => {
		setEventFormVisible(true);
	};

	const handleEditButton = (event) => {
		setEvent(event);
		setEventFormVisible(true);
	};

	const handleOnCloseEventForm = () => {
		setEvent(null);
		setEventFormVisible(false);
	};

	const handleOnSuccessEventForm = () => {
		setEvent(null);
		setEventFormVisible(false);
		fetchEventList();
	};

	const handleOnSuccessLessonReportForm = (lesson, event) => {
		// replace event with new one
		const tempEvents = [...events];
		const index = events.findIndex((e) => e._id === event._id);
		if (index !== -1) {
			tempEvents[index] = event;
			setEvents(tempEvents);
			setEvent(event);
		}
	};

	const getListData = (value) => {
		const startedAt = dayjs(value).format("YYYY-MM-DD");
		const filteredEvents = events.filter(
			(event) => dayjs(event.startedAt).format("YYYY-MM-DD") === startedAt
		);

		return filteredEvents.map((event) => ({
			type: "success",
			content: event.name,
		}));
	};

	const getMonthData = (value) => {
		if (value.month() === 8) {
			return 1394;
		}
	};

	const monthCellRender = (value) => {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};

	const dateCellRender = (value) => {
		const listData = getListData(value);

		return (
			<ul className="events">
				{listData.map((item) => (
					<li key={item.content}>
						<Badge status={item.type} text={item.content} />
					</li>
				))}
			</ul>
		);
	};

	const cellRender = (current, info) => {
		if (info.type === "date") return dateCellRender(current);
		if (info.type === "month") return monthCellRender(current);

		return info.originNode;
	};

	const onSelect = (newValue) => {
		setDate(newValue);
		setSelectedDate(newValue);
	};

	const onPanelChange = (newValue) => {
		setMonth(dayjs(newValue).startOf("month").format("YYYY-MM-DD"));

		setDate(newValue);
	};

	return (
		<>
			<LessonReportFormModal
				isVisible={isLessonReportVisible}
				onClose={() => {
					setLessonReportVisible(false);
					setLesson(null);
				}}
				onSuccess={handleOnSuccessLessonReportForm}
				lesson={lesson}
				event={event}
				onLoading={(v) => setLoading(v)}
				isLoading={isLoading}
			/>
			<div className="">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Kalender"
							extra={
								<Space>
									{/* <SearchInput onSearch={handleSearch} /> */}
									<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
										<span className="ant-btn-text">Tambah</span>
									</Button>
								</Space>
							}>
							<Row gutter={[24, 0]}>
								<Col xs={24} xl={18}>
									<Calendar
										value={date}
										onSelect={onSelect}
										onPanelChange={onPanelChange}
										cellRender={cellRender}
									/>
								</Col>
								<Col xs={24} xl={6}>
									<Typography.Title level={5} style={{ marginTop: 0 }}>
										{selectedDate.format("DD MMMM YYYY")}
									</Typography.Title>
									{Object.keys(selectedDateEvents).length === 0 && (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
									<Timeline style={{ width: "100%", paddingTop: 12, paddingLeft: 8 }}>
										{Object.keys(selectedDateEvents).map((time) => (
											<Timeline.Item key={time}>
												<Typography.Paragraph style={{ marginBottom: 0 }} strong>
													{time}
												</Typography.Paragraph>
												<Typography.Paragraph style={{ marginBottom: 0 }}>
													{selectedDateEvents[time].map((event) => (
														<Tag
															key={event._id}
															color={event.type === "LESSON" ? "blue" : "green"}
															onClick={() => handleEditButton(event)}>
															{event.name}
															<EyeOutlined style={{ marginLeft: 4 }} />
														</Tag>
													))}
												</Typography.Paragraph>
											</Timeline.Item>
										))}
									</Timeline>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default EventPage;
